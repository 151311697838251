import $ from 'jquery';
import './shortcuts/main';
import './shortcuts/entry';

/* Allows inline call qr-code call */
import jrQrcode from 'jr-qrcode'; // eslint-disable-line

function supportsLocalStorage() {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
}

function savePercent(id, percent) {
  if (!supportsLocalStorage()) { return false; }
  console.log(`saving percent ${id} ${percent}`);
  localStorage.setItem(`wallabag.article.${id}.percent`, percent);
  return true;
}

function saveTitle(id, title) {
  if (!supportsLocalStorage()) { return false; }
  console.log(`saving title ${id} ${title}`);
  localStorage.setItem(`wallabag.article.${id}.title`, title);
  return true;
}

function retrievePercent(id, resized, scrollEvent) {
  if (!supportsLocalStorage()) { return false; }

  const bheight = $(document).height();
  const percent = localStorage[`wallabag.article.${id}.percent`];
  const scroll = bheight * percent;

  if (!resized) {
    document.removeEventListener('scroll', scrollEvent);
    window.scroll(0, scroll);
    setTimeout(() => {
      document.addEventListener('scroll', scrollEvent);
    }, 500);
  }

  return true;
}

export { savePercent, retrievePercent, saveTitle };
