import $ from 'jquery';

/* Materialize imports */
import 'materialize-css/dist/css/materialize.css';
import 'materialize-css/dist/js/materialize';

/* Global imports */
import '../_global/index';

/* Tools */
import { initExport, initFilters } from './js/tools';

/* Import shortcuts */
import './js/shortcuts/main';
import './js/shortcuts/entry';

/* Theme style */
import './css/index.scss';

const mobileMaxWidth = 993;

(function init() {
  document.documentElement.classList.toggle('js');
}());

(function darkTheme() {
  const rootEl = document.querySelector('html');
  const themeDom = {
    darkClass: 'dark-theme',

    toggleClass(el) {
      return el.classList.toggle(this.darkClass);
    },

    addClass(el) {
      return el.classList.add(this.darkClass);
    },

    removeClass(el) {
      return el.classList.remove(this.darkClass);
    },
  };
  const themeCookie = {
    values: {
      light: 'light',
      dark: 'dark',
    },

    name: 'theme',

    getValue(isDarkTheme) {
      return isDarkTheme ? this.values.dark : this.values.light;
    },

    setCookie(isDarkTheme) {
      const value = this.getValue(isDarkTheme);
      document.cookie = `${this.name}=${value};samesite=Lax;path=/;max-age=31536000`;
    },

    removeCookie() {
      document.cookie = `${this.name}=auto;samesite=Lax;path=/;max-age=0`;
    },

    exists() {
      return document.cookie.split(';').some((cookie) => cookie.trim().startsWith(`${this.name}=`));
    },
  };
  const preferedColorScheme = {
    choose() {
      const themeCookieExists = themeCookie.exists();
      if (this.isAvailable() && !themeCookieExists) {
        const isPreferedColorSchemeDark = window.matchMedia('(prefers-color-scheme: dark)').matches === true;
        if (!themeCookieExists) {
          themeDom[isPreferedColorSchemeDark ? 'addClass' : 'removeClass'](rootEl);
        }
      }
    },

    isAvailable() {
      return typeof window.matchMedia === 'function';
    },

    init() {
      if (!this.isAvailable()) {
        return false;
      }
      this.choose();
      window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {
        this.choose();
      });
      return true;
    },
  };

  const addDarkThemeListeners = () => {
    $(document).ready(() => {
      const lightThemeButtons = document.querySelectorAll('.js-theme-toggle[data-theme="light"]');
      [...lightThemeButtons].map((lightThemeButton) => {
        lightThemeButton.addEventListener('click', (e) => {
          e.preventDefault();
          themeDom.removeClass(rootEl);
          themeCookie.setCookie(false);
        });
        return true;
      });
      const darkThemeButtons = document.querySelectorAll('.js-theme-toggle[data-theme="dark"]');
      [...darkThemeButtons].map((darkThemeButton) => {
        darkThemeButton.addEventListener('click', (e) => {
          e.preventDefault();
          themeDom.addClass(rootEl);
          themeCookie.setCookie(true);
        });
        return true;
      });
      const autoThemeButtons = document.querySelectorAll('.js-theme-toggle[data-theme="auto"]');
      [...autoThemeButtons].map((autoThemeButton) => {
        autoThemeButton.addEventListener('click', (e) => {
          e.preventDefault();
          themeCookie.removeCookie();
          preferedColorScheme.choose();
        });
        return true;
      });
    });
  };

  preferedColorScheme.init();
  addDarkThemeListeners();
}());

const stickyNav = () => {
  const nav = $('.js-entry-nav-top');
  $('[data-toggle="actions"]').click(() => {
    nav.toggleClass('entry-nav-top--sticky');
  });
};

const articleScroll = () => {
  const articleEl = $('#article');
  if (articleEl.length > 0) {
    $(window).scroll(() => {
      const s = $(window).scrollTop();
      const d = $(document).height();
      const c = $(window).height();
      const articleElBottom = articleEl.offset().top + articleEl.height();
      const scrollPercent = (s / (d - c)) * 100;
      $('.progress .determinate').css('width', `${scrollPercent}%`);
      const fixedActionBtn = $('.js-fixed-action-btn');
      const toggleScrollDataName = 'toggle-auto';
      if ((s + c) > articleElBottom) {
        fixedActionBtn.data(toggleScrollDataName, true);
        fixedActionBtn.openFAB();
      } else if (fixedActionBtn.data(toggleScrollDataName) === true) {
        fixedActionBtn.data(toggleScrollDataName, false);
        fixedActionBtn.closeFAB();
      }
    });
  }
};

const entriesStarted = () => {
  const articleEl = $('#article');
  if (articleEl.length === 0) {
    const articleIds = Object.keys(localStorage).map((key) => {
      if (key.endsWith('percent')) {
        const articleId = key.replace('wallabag.article.', '').replace('.percent', '');
        return articleId;
      }
      return false;
    }).filter((el) => el).sort();
    if (!articleIds.length) {
      return false;
    }
    const entriesEl = document.querySelector('.entries');
    const containerEl = document.createElement('div');

    const ulEl = document.createElement('ul');
    ulEl.classList.add('entries-started-list');
    articleIds.map((id) => {
      const title = localStorage.getItem(`wallabag.article.${id}.title`) || '';
      const percent = localStorage.getItem(`wallabag.article.${id}.percent`) || 0;
      const percentStr = percent > 0 ? ` (${Math.round(percent * 100)}%)` : '';
      const liEl = document.createElement('li');
      const trashEl = document.createElement('button');
      trashEl.addEventListener('click', () => {
        // @Simon share local storage handling
        localStorage.removeItem(`wallabag.article.${id}.percent`);
        localStorage.removeItem(`wallabag.article.${id}.title`);
        ulEl.removeChild(liEl);
      });
      trashEl.innerHTML = '<i class="material-icons small">delete</i>';
      const aEl = document.createElement('a');
      aEl.href = `/view/${id}`;
      aEl.textContent = title.length ? title + percentStr : id;
      liEl.append(aEl);
      liEl.append(trashEl);
      ulEl.append(liEl);
      return true;
    });
    containerEl.append(ulEl);
    entriesEl.parentNode.insertBefore(containerEl, entriesEl);
  }
  return true;
};

// Ne pas commit, le cookie est géré
// côté PHP src/Wallabag/CoreBundle/Controller/EntryController.php
// const titleRemember = () => {
//  const titles = document.querySelectorAll('.card-content .card-title');
//  [...titles].map((title) => title.addEventListener('click', () => {
//    const now = new Date();
//    const expireTime = now.getTime() + 1000 * 36000;
//    now.setTime(expireTime);
//    document.cookie =
//      `last-location=${document.location.href}; expires=${now.toGMTString()}; path=/;
//      SameSite=Strict`;
//  }));
// };

$(document).ready(() => {
  // sideNav
  $('.button-collapse').sideNav();
  $('select').material_select();
  $('.collapsible').collapsible({
    accordion: false,
  });
  $('.datepicker').pickadate({
    selectMonths: true,
    selectYears: 15,
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: false,
    format: 'yyyy-mm-dd',
    container: 'body',
  });

  $('.dropdown-trigger').dropdown({ hover: false });

  initFilters();
  initExport();
  stickyNav();
  articleScroll();
  entriesStarted();

  const toggleNav = (toShow, toFocus) => {
    $('.nav-panel-actions').hide(100);
    $(toShow).show(100);
    $(toFocus).focus();
  };

  $('#nav-btn-add-tag').on('click', () => {
    $('.nav-panel-add-tag').toggle(100);
    $('.nav-panel-menu').addClass('hidden');
    if (window.innerWidth < mobileMaxWidth) {
      $('.side-nav').sideNav('hide');
    }
    $('#tag_label').focus();
    return false;
  });

  $('#nav-btn-add').on('click', () => {
    toggleNav('.nav-panel-add', '#entry_url');
    return false;
  });

  const materialAddForm = $('.nav-panel-add');
  materialAddForm.on('submit', () => {
    materialAddForm.addClass('disabled');
    $('input#entry_url', materialAddForm).prop('readonly', true).trigger('blur');
  });

  $('#nav-btn-search').on('click', () => {
    toggleNav('.nav-panel-search', '#search_entry_term');
    return false;
  });

  $('.close').on('click', (e) => {
    $(e.target).parent('.nav-panel-item').hide(100);
    $('.nav-panel-actions').show(100);
    return false;
  });

  const mainCheckboxes = document.querySelectorAll('[data-js="checkboxes-toggle"]');
  if (mainCheckboxes.length) {
    [...mainCheckboxes].forEach((el) => {
      el.addEventListener('click', () => {
        const checkboxes = document.querySelectorAll(el.dataset.toggle);
        [...checkboxes].forEach((checkbox) => {
          const checkboxClone = checkbox;
          checkboxClone.checked = el.checked;
        });
      });
    });
  }
  $('form[name="form_mass_action"] input[name="tags"]').on('keydown', (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      $('form[name="form_mass_action"] button[name="tag"]').trigger('click');
    }
  });

  const getAction = (value) => {
    let action = {};
    switch (value) {
      case 'done':
        action = {
          type: 'archive',
          newValue: 'unarchive',
          objectValue: 1,
        };
        break;
      case 'unarchive':
        action = {
          type: 'archive',
          newValue: 'done',
          objectValue: 0,
        };
        break;
      case 'star_border':
        action = {
          type: 'starred',
          newValue: 'star',
          objectValue: 1,
        };
        break;
      case 'star':
        action = {
          type: 'starred',
          newValue: 'star_border',
          objectValue: 0,
        };
        break;
      case 'delete':
        action = {
          newValue: 'delete_forever',
        };
        break;
      default:
        break;
    }
    return action;
  };

  const syncIssue = (el, oldValue) => {
    el.innerText = 'sync_problem'; // eslint-disable-line no-param-reassign
    setTimeout(() => {
      el.innerText = oldValue; // eslint-disable-line no-param-reassign
    }, 5000);
  };

  /* eslint-disable */
  function AppToken() {}
  AppToken.prototype = {
    headers: null,
    setHeaders: function () {
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      this.headers = headers;
    },
    getHeaders: function () {
      this.setToken();
      return this.headers;
    },
    token: '',
    setToken: function () {
      return fetch(
        '/oauth/v2/token',
        {
          headers: this.headers,
          method: 'POST',
          body: `{"grant_type":"client_credentials","client_id":"4_quddrfanu2o0ksgcs4sks0ccw88gk84kc8sck4w4s840c0kcg","client_secret":"zl750ffwbms44g4s8s4wokwkoc88wk8gcskkgsks844kcsks4"}`,
        },
      )
        .then(res => res.json())
        .then((token) => {
          this.token = token;
          this.headers.append('Authorization', `Bearer ${token.access_token}`);
        });
    },
    init: function () {
      this.setHeaders();
    },
  };
  /* eslint-enable */
  const token = new AppToken();
  token.setHeaders();
  // token.setToken().then(() => {
  //   const entriesIds = [...document.querySelectorAll('.data > [data-entry-id]')]
  //     .map(el => el.dataset.entryId);
  //   fetch(
  //     `/api/entries/${entriesIds[0]}.json`,
  //     {
  //       headers: token.headers,
  //       method: 'GET',
  //     },
  //   ).then((res) => {
  //     if (res.status === 200) {
  //       res.json().then(content =>
  //        localStorage.setItem(`entry-${entriesIds[0]}`, JSON.stringify(content))
  //       );
  //     }
  //   });
  // });

  $('[data-action]').click((e) => {
    e.preventDefault();
    const target = e.currentTarget;
    const currentValue = target.innerText;
    if (currentValue === 'sync') {
      return false;
    }
    const action = getAction(currentValue);
    const icon = target.querySelector('i');
    icon.innerText = 'sync';
    let confirmValue = true;
    if (typeof (target.dataset.actionConfirm) === 'string') {
      confirmValue = window.confirm(target.dataset.actionConfirm);
    }
    if (confirmValue === false) {
      return false;
    }
    const method = currentValue === 'delete' ? 'DELETE' : 'PATCH';
    const body = currentValue === 'delete' ? undefined : `{ "${action.type}": ${action.objectValue} }`;
    token.setToken().then(() => {
      fetch(
        `/api/entries/${target.dataset.entryId}.json`,
        {
          headers: token.headers,
          method,
          body,
        },
      ).then((res) => {
        if (res.status === 200) {
          if (currentValue === 'delete') {
            const parentEl = target.parentElement;
            parentEl.querySelector('[data-action="archived"]').remove();
            parentEl.querySelector('[data-action="star"]').remove();
          }
          icon.innerText = action.newValue;
        } else {
          syncIssue(icon, currentValue);
        }
      });
    });
    return true;
  });
});
